@import "~hestia-component-library/dist/esm/tokens.scss";

.h-table-body .h-table-line .h-table-cell:nth-child(6) {
    text-align: end;
    padding: 4px 12px 4px 0;
}

#scrollTableTarget .h-grid-container .h-grid-row {
    margin-bottom: $space-base8-24;
}

